var color1 = page.club.color1;
var color2 = page.club.color2;
var color3 = page.club.color3;
//color3 = hexToRGB('#'+color3);

var darkmode = page.club.darkmode;
//console.log(color1)

var styling = ":root {--color1: "+color1+"; --color2: "+color2+"; --color3: "+color3+";}"

$('iframe').load(function() {
                $("iframe").contents().find("head").append("<style type='text/css'>"+styling+"</style>"); 
            });
	
	var pageBG = "255,255,255"	;	
if( darkmode == 1 ){
	$('body').addClass('darkmode');
	
	$('iframe').load(function() { 
				$("iframe").contents().find("body").addClass('darkmode');
            });
	pageBG = "100,100,100";
}


// function from https://stackoverflow.com/a/9733420/3695983                     
function luminance(r, g, b) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
    : Math.pow( (v + 0.055) / 1.055, 2.4 );
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function calculateRatio() {
	
var pageRGB = pageBG.split(',');
var color1RGB =  color1.split(',');

var pageR = pageRGB[0];
var pageG = pageRGB[1];
var pageB = pageRGB[2];

var colorR = color1RGB[0];
var colorG = color1RGB[1];
var colorB = color1RGB[2];

  // calculate the relative luminance
  const color1luminance = luminance(colorR, colorG, colorB);
  const pageBGluminance = luminance(pageR, pageG, pageB);

  // calculate the color contrast ratio
  const ratio = color1luminance > pageBGluminance 
  ? ((pageBGluminance + 0.05) / (color1luminance + 0.05))
  : ((color1luminance + 0.05) / (pageBGluminance + 0.05));

  return ratio;
}


  const ratio = calculateRatio();
  
 //console.log(ratio)
  if( ratio > 0.5 ){
	  
	  styling += ".copy a, .copy a:hover, .copy a:visited{color: rgba(var(--color2),1);}";
	  styling += "#documents a, #documents a:hover, #documents a:visited{color: rgba(var(--color2),1);}";
	  styling += "a[data-readmore-toggle], a[data-readmore-toggle]:hover, a[data-readmore-toggle]:visited{color: rgba(var(--color2),1);}";
	  styling += "h2.title {color: rgba(var(--color2),1);}";
	 
  }
  $("<style type='text/css'>"+styling+"</style>").appendTo("head");
  
  
  