
var player;


	// 2. This code loads the IFrame Player API code asynchronously.
      var tag = document.createElement('script');

      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // 3. This function creates an <iframe> (and YouTube player)
      //    after the API code downloads.
 
	  
     function onYouTubeIframeAPIReady() {
		 
	if(page.page_details.video){
		
		
		let videoID = page.page_details.video
		//	player.loadVideoById({'videoId': videoID});

		var video_position = page.page_details.video_position;
		
		if( !video_position ){
			page.page_details.video_position = "bottom";	
		}

	
		
        player = new YT.Player('video_player', {
			height: "100%",
    		width: "100%",
			videoId: videoID,
          playerVars: {
			'rel': 0,
			'autoplay': 0,
			'controls': 1, 
			'color': 'white', 
			'autohide': 1,
			'showinfo' : 0, // <- This part here
			'wmode': 'opaque',
          },
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
          }
        });
		 
		}
      }

      // 4. The API will call this function when the video player is ready.
      function onPlayerReady(event) {
       // event.target.playVideo
	
		
      }

      // 5. The API calls this function when the player's state changes.
      //    The function indicates that when playing a video (state=1),
      //    the player should play for six seconds and then stop.
      var done = false;
      function onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.PLAYING && !done) {
         // setTimeout(stopVideo, 6000);
          done = true;
        }
      }
      function stopVideo() {
        player.stopVideo();
      }	 

function playVideo() {
       stopVideo(); 
		player.playVideo();
	
      }	 
;
(function($, document, window, undefined) {
    'use strict'; //this is just saying how to validate your JS.  If you use a tool like JSHint you can see your mistakes - test your code quality.
    // declare any variables you want to keep in the scope - keep refering to and updating in your functions.
/*console.log(page)
	console.log(page.page_details.copy)	
	
	console.log(page.page_details.copy.length)	*/
	var clubID = page.club.id;

	var clubType = page.club.club_type;

				

	
	
	lightbox.option({
      'resizeDuration': 500,
      'wrapAround': true,
	  'alwaysShowNavOnTouchDevices': true
    })

	
function doRecaptcha(action){
	grecaptcha.ready(function() {
            grecaptcha.execute('6LeltSgdAAAAAEVRG44wEx9vI3jmhylmrU9ntRM1', {action: 'submit'}).then(function(token) {
				
                
				$('#contactForm input[name=action]').val(action);
				
				if( token) {
					$('#contactForm input[name=token]').val(token);
					
					$("#contactForm #submit").prop('disabled',true).html('<i class="fal fa-spinner fa-spin fa-1x fa-fw"></i>')
					
					
					$('#contactForm').submit();
				}
            });
	
	  });
}


	
var header_description = page.page_details.header_description;
	if( page.page_details.img ){	
		if( header_description ){
			$('.page_img img').attr('aria-description', header_description)
		}
	}
	

if(page.page_details.copy){
	
		//if(page.page_details.copy.length > 15){
			$(".inner_container #copy").removeClass("hide");
			$(".inner_container .page_details").removeClass("hide");
		//}
	}

	if(page.page_details.img){

	//	$(".page_img").css("background-image", "url(" + page.page_details.img + ")").removeClass("hide");
		
		
	}

	if(page.page_details.banner){

		var banner_position = page.page_details.banner_position;
		
		if( !banner_position ){
			banner_position = "bottom";	
		}

		$("#banner_"+banner_position).removeClass("hide");
		
		if( !page.link ){
			$('.page_banner a').removeAttr('href');
		}
		var banner_description = page.page_details.banner_description;
		
		if( banner_description ){
			$('.page_banner img').attr('aria-description', banner_description)
		}
	}
	


	if(page.page_details.widget1){
		
		$('.page_details').addClass('with_widgets');
		
		var title = page.page_details.widget1.title;
		$("#widget1 .section_title div").html(title);
		
		var feed = page.page_details.widget1.feed;
		
		$("#widget1 .list").empty();
		
		$.each(feed, function(k, v){

            $(".feed_template").clone().removeClass("feed_template template").addClass("item").attr('id', 'item1_' + k).appendTo('#widget1 .list');
            $('#item1_' + k + ' .date').html(v.date);
            $('#item1_' + k + ' .copy').html(v.copy);
			});
			$("#widget1 .item .copy").linkify({
                    target: "_blank",
                    formatHref: {
                        mention: function(href) {

                        }
                    }
                });
		$("#widget1").removeClass("hide");
		$("#widgets").removeClass("hide");
	}

	if(page.page_details.widget2){
			$('.page_details').addClass('with_widgets');
		var title = page.page_details.widget2.title;
		$("#widget2 .section_title div").html(title);
		
		var feed = page.page_details.widget2.feed;
		
		$("#widget2 .list").empty();
		
		$.each(feed, function(k, v){

            $(".feed_template").clone().removeClass("feed_template template").addClass("item").attr('id', 'item2_' + k).appendTo('#widget2 .list');
            $('#item2_' + k + ' .date').html(v.date);
            $('#item2_' + k + ' .copy').html(v.copy);
});
			 $("#widget2 .item .copy").linkify({
                    target: "_blank",
                    formatHref: {
                        mention: function(href) {

                        }
                    }
                });
		$("#widget2").removeClass("hide");
		$("#widgets").removeClass("hide");
	}

	if(page.club.address){

		$("#address").removeClass("hide");
	}

	if(page.club.postcode){

		$("#map").removeClass("hide");
	}

	if(page.committee){

		  $.each(page.committee, function(k, v){
			  $(".committee_template").clone().removeClass("committee_template template").addClass("committee").attr('id', 'committee_' + v.id).appendTo('#committee .list');
			  $("#committee_" + v.id + " .name").html('<strong>'+v.name+'</strong>');
			  $("#committee_" + v.id + " .position").html(v.position);
			  $('#committee_' + v.id + ' img').attr("src", "/avatar/" + v.user_id);
  });
  
  }
  
  $('#members_list, .members_list_title').addClass('hide');
  if(page.page_details.members_list){
			$('.members_list_title button').html(page.page_details.members_list.title);
		  $.each(page.page_details.members_list.members, function(k, v){
			  $(".members_list_template").clone().removeClass("members_list_template template").addClass("member").attr('id', 'members_list_' + v.member_id).appendTo('#members_list .list');
			  $("#members_list_" + v.member_id + " .name").html('<strong>'+v.name+'</strong>');
			  $("#members_list_" + v.member_id + " .qualifications").html(v.qualifications);
			  $('#members_list_' + v.member_id + ' img').attr("src", "/avatar/" + v.user_id);
  });
  
    $('#members_list, .members_list_title, .page_details').removeClass('hide');
  }
  
  
  if(page.leaders){

		  $.each(page.leaders, function(k, v){
			  $(".leader_template").clone().removeClass("leader_template template").addClass("leader").attr('id', 'leaders_' + v.id).appendTo('#leaders .list');
			 $("#leaders_" + v.id + " .name").html('<strong>'+v.name+'</strong>');


			  $("#leaders_" + v.id + " .qualifications").html(v.qualifications);


			  $('#leaders_' + v.id + ' img').attr("src", "/avatar/" + v.id);
  });
  }

    if(page.documents){

		  $.each(page.documents, function(k, v){

		  if(v.id_title == "clubconstitution"){
			 $("#clubconstitution").removeClass("hide");
			 $("#clubconstitution a").attr("href",v.href);
		  }
		  else if(v.id_title == "codeofconduct"){
			 $("#codeofconduct").removeClass("hide");
			 $("#codeofconduct a").attr("href",v.href);
		  }
		  else if(v.id_title == "safeguardingpolicy"){
			 $("#safeguardingpolicy").removeClass("hide");
			  $("#safeguardingpolicy a").attr("href",v.href);
		  }
		  else {

		  $(".document_template").clone().removeClass("document_template template").addClass("document").attr('id', 'document_' + v.id).appendTo('#documents .list');
			  $("#document_" + v.id + " .name").html('<i class="fa-solid fa-file-pdf"></i> <a href=" '+v.href+ '" target="_blank"> '+v.title+' </a>');
			}
  });

  }

  if(page.member_races){


	    $.each(page.member_races, function(k, v){

			  $(".member_races_template").clone().removeClass("member_races_template template").addClass("race").attr('id', 'memberrace_' + v.id).appendTo('#member_races .list');
			  $("#memberrace_" + v.id + " .date").html(v.date);
			  $("#memberrace_" + v.id + " .title").html('<strong>'+v.title+'</strong>');

		});
  }

	  if(page.club_hosted_races){

	    $.each(page.club_hosted_races, function(k, v){

			  $(".club_races_template").clone().removeClass("club_races_template template").addClass("race").attr('id', 'hostedrace_' + v.id).appendTo('#club_hosted_races .list');
			  $("#hostedrace_" + v.id + " .date").html(v.date);
			  $("#hostedrace_" + v.id + " .copy").html(v.copy);
			  $("#hostedrace_" + v.id + " .title").html('<a href="/events/'+v.title+'">'+v.title+'</a>');

			  if( v.results ){

				  $("#hostedrace_" + v.id + " .see_results").removeClass("hide");
			  }
			  
			  $("#club_hosted_races").removeClass("hide");
		});
			
  }
	if(page.event_details){
		
		const linkyfyOptions = { 
		
				  attributes: null,
				  className: 'linkified',
				  defaultProtocol: 'http',
				  events: null,
				  format: function (value, type) {
					return value;
				  },
				  formatHref: function (href, type) {
					return href;
				  },
				  ignoreTags: [],
				  nl2br: false,
				  tagName: 'a',
				  target: {
					url: '_blank'
				  },
				  validate: true
		
		}

	    $.each(page.event_details, function(k, v){

			  $(".calendar_template").clone().removeClass("calendar_template template").addClass("event").attr('id', 'event_' + v.event_id).appendTo('#calendar .list');
			  $("#event_" + v.event_id + " .date").html(v.start_time);
			  $("#event_" + v.event_id + " .title").html(v.title);
			  $("#event_" + v.event_id + " .copy").html( linkifyHtml(v.notes, linkyfyOptions) );
			  $("#event_" + v.event_id + " .location").html(v.location);
			  
				

		});
	}
	
		if(page.page_details.albums){

	    $.each(page.page_details.albums, function(k, v){
			
			var albumID = 'album_'+v.id;
			
			albumID = albumID.replace('.','_');
			var carouselID = 'carousel_'+v.id;
			
			var loopID = k;
			
			  $(".carousel_template").clone().removeClass("carousel_template template").addClass("album").attr('id', albumID).appendTo('#albums .list');
			
			 
			$('#'+ albumID + ' .title').html(v.title);
			
			/* ADD ID TO CAROUSEL */
			$('#'+ albumID + ' .carousel_wrapper .carousel').attr('id',carouselID);
			
			$.each(v.images, function(k, v){
				$(".lightbox_img_template").clone().removeClass("lightbox_img_template template").addClass("lightbox_img").attr('id', 'image_' +albumID + k).appendTo('#'+ albumID + ' .carousel_wrapper .carousel');
				$('#image_' +albumID + k).attr('href',v).attr('data-lightbox',loopID);
				$('#image_' +albumID + k + ' img').attr('src',v);
				
			});
			
			
			
			

		});
			

	
		$(".page_details").removeClass("hide");
		$('#albums').removeClass('hide');
	}
	
	  if(page.event_reports){
		  
		 // $("form[name=eventreportsearch] input[name=club_id]").val(clubID);
		  
		  		  $.each(page.event_reports, function(k, v){
					  
					  var reportID = v.id;

            $(".report_template").clone().removeClass("report_template template").addClass("report").attr('id', 'report_' + v.id).appendTo('#reports .list');

			$('#report_' + reportID + ' .name').html(v.name);
            $('#report_' + reportID + ' .title').html(v.title);
			$('#report_' + reportID + ' .copy_cont .copy').html(v.copy);
			
			
			$('#report_' + reportID + ' .copy_cont .copy').linkify({
                    target: "_blank",
                    formatHref: {
                        mention: function(href) {

                        }
                    }
                });
				
			
			$('#report_' + reportID + ' .copy_cont .copy').readmore({
			  speed: 75,
			  lessLink: '<a href="#">Read less</a>',
			  moreLink: '<a href="#">Read more</a>',
			  collapsedHeight: 100
				});	
			
			if(  v.photos.length > 0 ){
							
							$.each(v.photos, function(k, v){
								
								//console.log(v);
								
									$('#report_' + reportID + ' .copy_cont .photos').append('<div><img src="'+v+'" /></div>');	
							
							});
							
				$('#report_' + reportID + ' .copy_cont .photos').slick({
								 lazyLoad: 'ondemand',
									   dots: true,
									   arrows: false,
									  // rtl: true,
									 // infinite: true,
									 // speed: 300,
									 // slidesToShow: 1,
  									  //slidesToScroll: 1,
									//  centerMode: true,
									  //variableWidth: false
								});
													
						
					}		
				
			
			});
		  
	  }

	if(page.twitter){

		$("#twitter").removeClass("hide");

		  $.each(page.twitter, function(k, v){

			    var created_at = moment(v.created_at).format('H:mm dddd Do MMMM YYYY');

            $(".twitter_template").clone().removeClass("twitter_template template").addClass("tweet").attr('id', 'tweet_' + k).appendTo('#twitter .list');
            $('#tweet_' + k + ' .date').html(created_at);
            $('#tweet_' + k + ' .copy').html(v.text);

			  $("#tweet_" + k + " .copy").linkify({
                    target: "_blank",
                    formatHref: {
                        mention: function(href) {

                        }
                    }
                });

			});
  }

		if(page.news){

		  $.each(page.news, function(k, v){

            $(".news_template").clone().removeClass("news_template template").addClass("news").attr('id', 'news_' + v.id).appendTo('#news .list');

            $('#news_' + v.id + ' .date').html(v.date);
			$('#news_' + v.id + ' .title').html(v.title);
            $('#news_' + v.id + ' .copy').html(v.copy);


 $('#news_' + v.id + ' .copy').readmore({
			  speed: 75,
			  lessLink: '<a href="#">Read less</a>',
			  moreLink: '<a href="#">Read more</a>',
			  collapsedHeight: 100
				});	
			if(v.photo){
			$('#news_' + v.id + ' .photo img').attr("src",v.photo);
			$('#news_' + v.id + ' .photo').removeClass("hide");
			}
			
			
			  $("#news_" + v.id + " .copy").linkify({
                    target: "_blank",
                    formatHref: {
                        mention: function(href) {

                        }
                    }
                });

			});
  }


		if(page.training ){

		$('#training .month_year').html(page.training.dates.month_year)

		var i = 1;

		var days = page.training.dates.days_in_month;
		var first_day = page.training.dates.first_day;
		var first_day_number = page.training.dates.first_day_number;

		var totalDays = parseInt(days)+ parseInt(first_day_number) -1 ;

	while( i <= totalDays){

		var addition = i-first_day_number;

		if(i<first_day_number){

			$(".day_template").clone().removeClass("day_template template").empty().addClass('blankday').appendTo('#training .list');

		}
		else if( i >= first_day_number ){

		var thisDay = i - first_day_number + 1;

		 $(".day_template").clone().removeClass("day_template template").addClass('weekday').attr('id', 'day_' + thisDay).appendTo('#training .list');

		 var day = moment(page.training.dates.first_day).add(addition, 'days').format('ddd D');

		 $('#day_' + thisDay + ' .dayofweek').html(day);
		 }

		 i++;

	}

$.each(page.training.sessions, function(k, v) {

		var date = v.date;

		$('#day_'+date ).addClass("event");

		 $(".session_template").clone().removeClass("session_template template").attr('id', 'session_' + v.id).appendTo('#day_' + date +' .calpanel_cont .calpanel .details');
			
	 $('#session_'+v.id+' .date' ).html(v.session_date);
		 $('#session_'+v.id+' .time' ).html(v.time);

		 $('#day_' + date +' .expand i' ).show();


	});

	$.each(page.training.groups, function(k, v) {

		 $(".group_template").clone().removeClass("group_template template").addClass("group").attr('id', 'group_' + v.id).appendTo('#session_' + v.training_id);

		 $('#session_' + v.training_id + ' .time').html(v.time);

		 $('#group_' + v.id).css({'border-left':'solid 10px '+ v.color});

		 $('#group_' + v.id + ' .title').append(v.title);
		
		 $('#group_' + v.id + ' .location').append(v.location);
		 $('#group_' + v.id + ' .runleader').append(v.leader);

	});	/**/
		}
		
		
		if(page.page_details.table){

		
		$(".page_details .table").removeClass("hide");
		$(".page_details").removeClass("hide");
		
		
		$('.page_copy').linkify({
                    target: "_blank",
                    formatHref: {
                        mention: function(href) {

                        }
                    }
                });
		
		}

	if(page.order_received){
		
		$('.basket').removeClass('hide');
		
	}
	
if(page.basket){	
	if(page.basket.length){
		
		$('.basket').removeClass('hide');
		
		var basketCount = page.basket.length;
		$(".basket span").attr('data-count', basketCount);
		
	}
}
	
if(page.shop_items){
		//console.log(page)
				
				
				
					$.each(page.shop_items, function(k, v) {
				
				var ID = v.id;
				
				 $(".shop_item_template").clone().removeClass("shop_item_template template").addClass("shop_item").attr('id', 'shop_item_' + ID).appendTo('#shop .list');
						
						
				 
			  	$('#shop_item_' + ID + ' .item_card .item_conatiner .title').html(v.title);
				
						if( v.price_min){
							$('#shop_item_' + ID + ' .item_card .item_conatiner .price span').html(v.price_min);	
						}
						else{
							$('#shop_item_' + ID + ' .item_card .item_conatiner .price span').html(v.price);	
						}
				
						
				if( v.image){
					$('#shop_item_' + ID + '  .item_card .image_icon').html( '<img src="'+v.image+'" />' ); 
				}		
						
				else if( v.icon){
					$('#shop_item_' + ID + '  .item_card .image_icon').html( '<i class="fad fa-'+v.icon+' fa-3x"></i>' ); 
				}
				
						
				if( v.department){
					
					$('#shop_item_' + ID).attr('data-department',v.department)
					
					
				}
	
	
		}); 
	
		 
		 	} 
		if(page.finalbasket ){
		$.each(page.finalbasket, function(k, v) {

		 $(".product_template").clone().removeClass("product_template template").addClass("item").attr('id', 'item_' + v.id).appendTo('#basket_cont .list');
		 
		 $('#item_' + v.id + ' .product_name').html(v.item);
		 
		 if(v.cost){
		 $('#item_' + v.id + ' .price ').html(v.cost);
		 }
		 else{
		 $('#item_' + v.id + ' .price ').html(v.outofstock).removeClass('price');
		 }
		 
		if ( v.size ){
		 $('#item_' + v.id + ' .size').html(v.size.toUpperCase());
		}
		else{
			$('#item_' + v.id + ' .size').removeClass("size");
			
		}
		 
		  if(v.icon){
		 $('#item_' + v.id + ' .photo').html('<i class="fad fa-3x fa-'+v.icon+'"></i>');
		 }
		 else{
		 $('#item_' + v.id + ' .photo img').attr("src",v.image_file);
		 }
		 
		 if( !v.image_file ){
			 
			$('#item_' + v.id + ' .photo img').remove(); 
		 }

		 });
		 
		 $('#basket_cont .total').text(page.finalbasketTotal);
		 
		 if( page.finalbasketTotal > 0){
			 
			 $("form[name=place_order]").removeClass("hide");
			 
		 }

		 }
			 	 
		if(page.checkoutbasket ){

		$.each(page.checkoutbasket, function(k, v) {

		 $(".product_template").clone().removeClass("product_template template").addClass("item").attr('id', 'item_' + v.id).appendTo('#checkout_cont .list');
		 
		 $('#item_' + v.id + ' .product_name').html(v.item);
		 
		 if(v.cost){
		 $('#item_' + v.id + ' .price ').html(v.cost);
		 }
		 else{
		 $('#item_' + v.id + ' .price ').html(v.outofstock).removeClass('price');
		 }
		 
		 });
		 
		 $('#checkout_cont .total').text(page.finalbasketTotal);
		

		 }
		  
		 if(page.payment_keys){
			// console.log(page.payment_keys)
			 
			  if(page.payment_keys.stripe_public_key)
			{
			   var stripeKey = page.payment_keys.stripe_public_key;
			   var stripe = Stripe(stripeKey);
			   var elements = stripe.elements();
			 }
			 
			 
			 $("iframe").on("load", function() {
  var head = $("iframe").contents().find("head");
 // let css = '<style>/********* Put your styles here **********</style>';
 // $(head).append(css);
});
			 
	}
	
	
	
	

    // DOCUMENT READY
    $(function() {
		
	

/*
		if(clubType == "Running") { $('.run_logo').removeClass("hide");
									$('.tri_logo').remove();
									 }
		if(clubType == "Tri") { $('.tri_logo').removeClass("hide");
								$('.run_logo').remove();
		}
*/

	// ADD RACE MONTHS TO SELECT MENU

	if( $("select[name=race_month]").length ){

		var minMonth =  moment(page.club.joined).format('Y-MM');

		var thisMonth = moment().format('Y-MM');
		var currentMonth = moment().format('MMMM Y');

		$("select[name=race_month]").append(' <option value="'+thisMonth+'">'+currentMonth+'</option> ');

		var diff = moment(thisMonth).diff(moment(minMonth), 'months', true)

				for (var i=1; i<=diff; i++){

		var thisMonth = moment().subtract(i,'months').format('Y-MM');
		var currentMonth = moment().subtract(i,'months').format('MMMM Y');

			$("select[name=race_month]").append(' <option value="'+thisMonth+'">'+currentMonth+'</option> ');

		}
	}



		
		
	$.each(page.club.association, function(k, v){
		
	
			if( v ){
				
			$(".association_logo_template").clone().removeClass("association_logo_template template").addClass("association_logo").attr('id', 'association_logo_' + v.img).appendTo('footer .association_logos');
		
		$('#association_logo_'+v.img+' img').attr('src',"/association_logo/"+v.img).attr('alt',v.name+' logo');
		$('#association_logo_'+v.img+' a').attr('href',v.url)
			}
		})

/**/

$('.owl-carousel').owlCarousel({
    loop:true,
    margin:0,
	items : 1, 
	center: true,
	dotsContainer: false,
	dots: false,
     /* itemsDesktop : false,
      itemsDesktopSmall : false,
      itemsTablet: false,
      itemsMobile : false,*/
		lazyLoad:true,	
			autoHeight:true,
			autoplay:true,
    autoplayTimeout:4000,
    autoplayHoverPause:true,
     responsiveClass:true,
 /* responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
				
            },
            1000: {
                items: 3
            }
        }*/
})

        bindEvents();
    });

    // EVENTS WHICH RELY ON THE DOM

    function bindEvents() {
		
		
		/* VIDEO PLAYER */
		
	$("#play_video").click(function(event){
   		event.preventDefault();
		playVideo()  
	});

		
		
		

	$('.dropdown').hover(function(){ 
		if ($(window).width() > 768)  {	
			$('.dropdown-toggle', this).trigger('click'); 
		}		
	});



$("#shop_departments").change(function(event){
		
		let dept = $(this).val();
	$('#shop .list .shop_item').removeClass('hide');	
	
		if (dept == 'all_items'){
		return false;
		}
	
	$('#shop .list .shop_item').each(function(i, obj) {
		
		let thisDept = $(this).attr('data-department');
		
		if( thisDept !== dept){
			$(this).addClass('hide');
		}
		
    //test
});
	
		
});
$('body').on('click', '#shop .list .shop_item', function(event) {

	 event.preventDefault();	
	
	let item_id = $(this).attr('id').replace(/[^\d]+/, '');
	
	
		 var dataString = {
            	 'item_id':item_id
            	};
				
            	dataString[site.csrf.keys.name] = site.csrf.name;
            	dataString[site.csrf.keys.value] = site.csrf.value;

            	var url = "/getClubShopItem";
            		{
                        // AJAX Code To Submit Form.
                        $.ajax({
                            type: "GET",
                            url:url,
                            data: dataString,
							dataType: "json",
                            cache: false,
                            success: function(result) {

							$('#shopItemModal .modal-title').html(result.item.title);	
							$('#shopItemModal .description').empty().html(result.item.description);	
							$('#shopItemModal .shop_item_list').empty();	
							$('#shopItemModal .images').empty();
							$('#shop_item_carousel').empty();
							$('#shopItemModal .options').empty();
							$("#shopItemModal .add_bundle_to_basket").closest('.buttons_container').removeClass('hide')
							$('#shopItemModal input[name=bundle_id]').val('')																			
							
								if( !result.item.bundle){
									
								$("#shopItemModal .add_bundle_to_basket").closest('.buttons_container').addClass('hide')
									
									
								$("#shopItemModal .modal_shop_item_template").clone().removeClass("modal_shop_item_template  template").addClass("modal_shop_item_cont").attr('id', 'modal_shop_item_cont').appendTo('#shopItemModal .shop_item_list');
						
						if( result.item.images.length > 0 ){
					
							$.each(result.item.images, function(k, v){
								
								var loopID = 0;
								
								$("#shopItemModal .shop_item_img_template").clone().removeClass("shop_item_img_template  template").addClass("lightbox_img").attr('id', 'shop_item_image_' + k).appendTo('#shop_item_carousel');
								$('#shop_item_image_' + k).attr('href',v).attr('data-lightbox',loopID);
								$('#shop_item_image_' + k + ' img').attr('src',v);
							});
			
							
							$('#shopItemModal .carousel_wrapper').show();
							
						}
								else{
									
									let icon = $('#shop_item_'+item_id+' .image_icon').html();
									
									$('#shopItemModal .images').html(icon)
									
							$('#shopItemModal .carousel_wrapper').hide();
								}
								
								
								
								$.each(result.item.options, function(k, v){
									
											if( v.option_id == 0 && v.quantity > 0){

												$('#shopItemModal .options').append('<button class="btn btn-cmc add_to_basket" data-item_id="'+ v.item_id +'" data-option_id="'+ v.id +'">'+ v.cost +'</button>');	

											}
											if( v.option_id > 0  && v.quantity > 0){

												$('#shopItemModal .options').append('<button class="btn btn-cmc add_to_basket" data-item_id="'+ v.item_id +'" data-option_id="'+ v.id +'">'+ v.option +' '+ v.cost +'</button>');	
											}
											});
								
									
									if($('#shopItemModal .options .add_to_basket').length == 0 ){
									   
									   $('#shopItemModal .options').html('Out of stock')
									   
									   }
								
									
								}
								
								if( result.item.bundle){
									
									$('#shopItemModal input[name=bundle_id]').val(result.item.bundle_option_id)
									
									let bundle_items = result.item.bundle_items
									
									$.each(bundle_items, function(k, v){
										
										let item_id = v.item_id
										let item_title = v.item_title
										
										$("#shopItemModal .modal_shop_bundle_template").clone().removeClass("modal_shop_bundle_template  template").addClass("modal_shop_bundle_cont").attr('id', 'modal_shop_bundle_cont_'+item_id).appendTo('#shopItemModal .shop_item_list');

										
										$('#modal_shop_bundle_cont_'+item_id+' .item_name').html(item_title)
										
										
											$.each(v.options, function(i, item){
												
												//console.log(item)
											
											if( item.option_id > 0  && item.quantity > 0){

												$('#modal_shop_bundle_cont_'+item_id+' .options').append('<button class="btn btn-cmc add_to_bundle"  data-option_id="'+ item.id +'">'+ item.option +'</button>');	
											}
												
											if( item.option_id == 0  && item.quantity > 0){

												$('#modal_shop_bundle_cont_'+item_id+' .options').append('<button class="btn btn-cmc add_to_bundle bundle_option_selected hide" data-option_id="'+ item.id +'">'+ item.option +'</button>');	
											}
											
												
										});
										
										
											
											if($('#modal_shop_bundle_cont_'+item_id+' .options .add_to_bundle').length == 0 ){
									   
											   $('#modal_shop_bundle_cont_'+item_id+' .options').html('Out of stock');
												
												
											   }	
									
									});
									$("#shopItemModal .add_bundle_to_basket").attr('disabled',true);
								}
								
								
							$('#shopItemModal').modal('show');	 
            					},
                            error: function(jqXHR, textStatus, errorThrown) {
                         console.log( jqXHR);
                            }
                        });
                    }
                    return false;

		});
		

		
$('body').on('click', '.collapser', function(event) {

	 event.preventDefault();

	$(".collapse").collapse('hide');

});/**/
		
	

$('#contactForm input, #contactForm textarea').focus(function(event){
	
		$(this).closest('.form-group').removeClass('has-error')
	});

$("#contactForm #submit").click(function(event){
	
	 event.preventDefault();
	
	let error = false;
	

	
	let name = $('#contactForm input[name=name]').val(); 
	
	if( !name ){
		$('#contactForm input[name=name]').closest('.form-group').addClass('has-error')
	}
	let email = $('#contactForm input[name=email]').val(); 
	if( !email ){
		$('#contactForm input[name=email]').closest('.form-group').addClass('has-error')
	}
	let subject = $('#contactForm input[name=subject]').val(); 
	if( !subject ){
		$('#contactForm input[name=subject]').closest('.form-group').addClass('has-error')
	}
	let message = $('#contactForm textarea[name=message]').val(); 
	if( !message ){
		$('#contactForm textarea[name=message]').closest('.form-group').addClass('has-error')
	}
	if( !name || !email || !subject || !message ){
		return false
	}
	
	
	

	doRecaptcha();
});

$("#contactForm").ufForm({
    validators: page.validators.contact,
}).on("submitSuccess.ufForm", function(event, data, textStatus, jqXHR) {
	//console.log(data);
location.reload()

}).on("submitError.ufForm", function(event, data, textStatus, jqXHR) {
	
	
	grecaptcha.reset();

	$("#contactForm #submit").prop('disabled',false).html('Submit')
	console.log(data);
});


$("select[name=race_month").change(function(){

	var month = $(this).val();

	 var dataString = {
	   'month':month,
	   'club_id':clubID
	};
	dataString[site.csrf.keys.name] = site.csrf.name;
	dataString[site.csrf.keys.value] = site.csrf.value;

	var url = "/getWebsiteClubRaceResults";
		{
            // AJAX Code To Submit Form.
            $.ajax({
                type: "GET",
                url:url,
                data: dataString,
                cache: false,
                success: function(result) {
				//	console.log( result);

				$('#member_races .list').empty();

				 $.each(result.member_races, function(k, v){

			  $(".member_races_template").clone().removeClass("member_races_template template").addClass("race").attr('id', 'memberrace_' + v.id).appendTo('#member_races .list');
			  $("#memberrace_" + v.id + " .date").html(v.date);
			  $("#memberrace_" + v.id + " .title").html('<strong>'+v.title+'</strong>');

		});
					},
                error: function(jqXHR, textStatus, errorThrown) {
             console.log( jqXHR);
                }
            });
        }
        return false;
});

  /* RACE RESULTS */

$('body').on('click', '.see_results', function(event) {

	 event.preventDefault();

	 var raceID = $(this).closest('.race').attr('id').replace(/[^\d]+/, '');

	 $('i',this).toggleClass('fa-chevron-down fa-chevron-up');

	 if( $('i',this).hasClass('fa-chevron-down') ){

		 $('#memberrace_'+ raceID +' .results').empty();

		 return false;
	 }

	 var dataString = {
	   'race_id':raceID,
	   'club_id':clubID
	};


	dataString[site.csrf.keys.name] = site.csrf.name;
	dataString[site.csrf.keys.value] = site.csrf.value;

	var url = "/getRaceResults";
		{
            // AJAX Code To Submit Form.
            $.ajax({
                type: "GET",
                url:url,
                data: dataString,
                cache: false,
                success: function(result) {
					$('#memberrace_'+ raceID +' .results').empty();

					$.each(result.member_results, function(k, v){

			  $(".memberresult_template").clone().removeClass("memberresult_template template").addClass("result").attr('id', 'member_result_' + v.id).appendTo('#memberrace_'+ raceID +' .results');
			  $("#member_result_" + v.id + " .name").html(v.name);
			  $("#member_result_" + v.id + " .time").html(v.time);

		})

					},
                error: function(jqXHR, textStatus, errorThrown) {
             console.log( jqXHR);
                }
            });
        }
        return false;

});


/* TRAINING */
		$('body').on('click', '.day', function(event) {
			if( $(this).closest(".day_cont").hasClass("event") ){

			var id = $(this).closest(".weekday").attr('id');
			
			$(".event").removeClass("selected");
			$(this).closest(".event").addClass("selected");
			
			$(".calpanel_cont").addClass("hide");
			$("#"+id+ " .calpanel_cont").removeClass("hide");

			}

			});


		$('body').on('click', '.calpanel_cont', function(event) {

			$(this).addClass("hide");
			$(".selected").removeClass("selected");

			$('html, body').animate({
    scrollTop: ($('#training .calpanel_cont .calpanel').offset().top)
				},500);

			});



		$('body').on('click', '.read_more', function(event) {

		$(this).text(function(i, text){
         			 return text === "Close" ? "Read more..." : "Close";
     			 });

		var cont = $(this).closest(".cont").attr('id');

		$("#"+cont+ ' .copy_cont').toggleClass('copy_hidden');

		 $("body,html").animate(
      {
        scrollTop: $("#"+cont ).offset().top
      },
      400 //speed
    );

			});

	$("#training .next, #training .prev").click(function(event){

		event.preventDefault();

				var month = $("#training .month_year").text();

				var direction = $(this).data("direction");

	 var dataString = {
	   'month':month,
	   'direction':direction
	};
	dataString[site.csrf.keys.name] = site.csrf.name;
	dataString[site.csrf.keys.value] = site.csrf.value;

	var url = "/getWebsiteClubTraining";
		{
            // AJAX Code To Submit Form.
            $.ajax({
                type: "GET",
                url:url,
                data: dataString,
                cache: false,
                success: function(result) {

					var month = result.training.dates.month_year;

					$("#training .month_year").text(month);

					$('#training .list').empty();


		var i = 1;

		var days = result.training.dates.days_in_month;
		var first_day = result.training.dates.first_day;
		var first_day_number = result.training.dates.first_day_number;

		var totalDays = parseInt(days)+ parseInt(first_day_number) -1 ;

	while( i <= totalDays){

		var addition = i-first_day_number;

		if(i<first_day_number){

			$(".day_template").clone().removeClass("day_template template").empty().addClass('blankday').appendTo('#training .list');

		}
		else if( i >= first_day_number ){

		var thisDay = i - first_day_number + 1;

		 $(".day_template").clone().removeClass("day_template template").addClass('weekday').attr('id', 'day_' + thisDay).appendTo('#training .list');

		 var day = moment(result.training.dates.first_day).add(addition, 'days').format('ddd D');

		 $('#day_' + thisDay + ' .dayofweek').html(day);
		 }

		 i++;

	}
/*
$.each(result.training.sessions, function(k, v) {

		var date = v.date;

		$('#day_'+date ).addClass("event");

		 $(".session_template").clone().removeClass("session_template template").attr('id', 'session_' + v.id).appendTo('#day_' + date +' calpanel_cont .calpanel');

		 $('#session_'+v.id+' .time' ).html(v.time);

		 $('#day_' + date +' .expand i' ).show();


	});

	$.each(result.training.groups, function(k, v) {

		 $(".group_template").clone().removeClass("group_template template").addClass("group").attr('id', 'group_' + v.id).appendTo('#session_' + v.training_id);

		 $('#session_' + v.training_id + ' .time').html(v.time);

		 $('#group_' + v.id + ' .title').append(v.title);

		 $('#group_' + v.id + ' .location').append(v.location);
		 $('#group_' + v.id + ' .runleader').append(v.leader);

	});*/

$.each(result.training.sessions, function(k, v) {

		var date = v.date;

		$('#day_'+date ).addClass("event");

		 $(".session_template").clone().removeClass("session_template template").attr('id', 'session_' + v.id).appendTo('#day_' + date +' .calpanel_cont .calpanel .details');

		 $('#session_'+v.id+' .time' ).html(v.time);

		 $('#day_' + date +' .expand i' ).show();


	});

	$.each(result.training.groups, function(k, v) {

		 $(".group_template").clone().removeClass("group_template template").addClass("group").attr('id', 'group_' + v.id).appendTo('#session_' + v.training_id);

		 $('#session_' + v.training_id + ' .time').html(v.time);

		 $('#group_' + v.id + ' .title').append(v.title);

		 $('#group_' + v.id + ' .location').append(v.location);
		 $('#group_' + v.id + ' .runleader').append(v.leader);

	});	
					},
                error: function(jqXHR, textStatus, errorThrown) {
             console.log( jqXHR);
                }
            });
        }
        return false;

			});

	$('body').on('change', '#product_cont .product .gender select', function(event) {

    		event.preventDefault();

        var product = $(this).closest('.product').attr('id').replace(/[^\d]+/, '');
        var gender = $(this).val();

          $("#product_"+product+" select").removeClass('inputerror');

    	 var dataString = {
    	   'product':product,
           'gender':gender
    	};

    	dataString[site.csrf.keys.name] = site.csrf.name;
    	dataString[site.csrf.keys.value] = site.csrf.value;

    	var url = "/getItemSizes";
    		{
                // AJAX Code To Submit Form.
                $.ajax({
                    type: "GET",
                    url:url,
                    data: dataString,
                    cache: false,
                    success: function(result) {

    				//	console.log(result)
//
              if(result.sizes.length){

                var sizes = result.sizes;

                  $('#product_'+product+' .size select[name=size]').html(sizes);
                  $('#product_'+product+' .size').removeClass('hide');
              }


    					},
                    error: function(jqXHR, textStatus, errorThrown) {
                 console.log( jqXHR);
                    }
                });
            }
            return false;

    			});


$('body').on('click', '#shop .shop_item .description .description_title ', function(event) {

            		event.preventDefault();
					
					$(this).closest('.description').find('.copy').toggleClass('hide');
					
					
								});
								
	
	
	$('body').on('click', '#shop .shop_item select[name=options]', function(event) {

            		event.preventDefault();
					
					$(this).removeClass('optionerror');
					
					
								});		
	
		
		$('body').on('click', '#shopItemModal .add_to_bundle', function(event) {
			
				event.preventDefault();
			
			$(this).closest('.modal_shop_bundle_cont').find('.btn').removeClass('bundle_option_selected')
			$(this).addClass('bundle_option_selected')
			
			/* check if all item sizes have been selected */
			
			let allSelected = false;
			let optionCount = $('.modal_shop_bundle_cont .options').length;
			let selected = 0;
			//console.log(optionCount)
			
			$('#shopItemModal  .modal_shop_bundle_cont .options').each(function(i, obj) {

				if( $(this).find('bundle_option_selected') ){
					selected++;
				}
					

			});
			//console.log(selected)
			if( selected == optionCount){
				
			   $('#shopItemModal .add_bundle_to_basket').attr('disabled',false)
			   
			   }
			
		});
		
		
   $('body').on('click', '#shopItemModal .add_to_basket,#shopItemModal .add_bundle_to_basket', function(event) {
			  
	   	event.preventDefault();
	    let dataString,item_id,bundleID;
	   
	   let optionIDs = [];
	   
	   if( $(this).hasClass('add_bundle_to_basket')){
		   
		   item_id = $('#shopItemModal input[name=bundle_id]').val();
		   
		   	$('#shopItemModal .modal_shop_bundle_cont .options').each(function(i, obj) {

				let option_id =	$(this).find('.bundle_option_selected').attr('data-option_id')
				
				optionIDs.push(option_id)
			});
		   
		   
		   dataString = {
				  "item_id": item_id,
				"bundle": optionIDs
			};
		  
	   }
	   else{
		   
		   item_id = $(this).attr('data-option_id');
		   
		    dataString = {
				"item_id": item_id,
				"bundle": null
			};
	   }
			 
		 //  console.log(dataString)
	   
            	dataString[site.csrf.keys.name] = site.csrf.name;
            	dataString[site.csrf.keys.value] = site.csrf.value;

            	var url = "/addToBasket";
            		{
                        // AJAX Code To Submit Form.
                        $.ajax({
                            type: "POST",
                            url:url,
                            data: dataString,
							dataType: 'json',
							cache: false,
                            success: function(result) {

			//	console.log(result);
								
								if( result.out_of_stock){
									 $('#shopItemModal .message').html(result.message)
									
									$('#shopItemModal .add_to_basket').each(function(i, obj) {
										
										if( $(this).attr('data-option_id') == item_id){
											$(this).attr('disabled',true)
										}
										
										});
								
									
									$('#shopItemModal .add_bundle_to_basket').attr('disabled',true)
								
								setTimeout(function() { 
								$('#shopItemModal .message').empty()
							}, 2000);
								
									
								}
								
          $(".basket").removeClass('hide').addClass('basket-glow');
          $(".basket span").attr('data-count', result.count);
		  
		  //$('#'+shopID+' select').val(0);

            					},
                            error: function(jqXHR, textStatus, errorThrown) {
                         console.log( jqXHR);
								
									   
		    // Display errors on failure
    var debugAjax = (typeof site !== "undefined") && site.debug.ajax;

    if (debugAjax && jqXHR.responseText) {
        document.write(jqXHR.responseText);
        document.close();
    } else {
        // Destroy any previous instance
        $("#alerts-page").ufAlerts('destroy');
        // Create new instance of ufAlerts
        $("#alerts-page").ufAlerts().ufAlerts('fetch').ufAlerts('render');
    }		
								
                            }
                        });
                    }
                    return false;

            			});
		
	  $('body').on('click', '#basket_cont .item .delete, .empty_basket', function(event) {

            		event.preventDefault();

		  		  let item = null;
				  let empty_basket = false;

				  if( $(this).hasClass('empty_basket')){
					  empty_basket = true;
				  }
				  else{
						item = $(this).closest('.item').attr('id').replace(/[^\d]+/, '');

				  }

            	 var dataString = {
            	 	'item':item,
					 'empty_basket':empty_basket
            	};
		  
		  
            	dataString[site.csrf.keys.name] = site.csrf.name;
            	dataString[site.csrf.keys.value] = site.csrf.value;

            	var url = "/delFromBasket";
            		{
                        // AJAX Code To Submit Form.
                        $.ajax({
                            type: "POST",
                            url:url,
                            data: dataString,
                            cache: false,
                            success: function(result) {

            				//console.log(result)

    // location.reload();
								
								if( empty_basket === true ){
									
									location.reload();
									
									return false;
								}
								
								$('#item_'+item).remove();

            					},
                            error: function(jqXHR, textStatus, errorThrown) {
                         console.log( jqXHR);
                            }
                        });
                    }
                    return false;

            			});	


$("#category_links a").click(function(){

var category = $(this).data('category');

//console.log(category);
  
  		// element which needs to be scrolled to
var element = '#'+category;

document.querySelector(element).scrollIntoView({
  behavior: 'smooth' 
});
  
  
		});
		
		
		 $("#eventreportsearch").submit(function(event){
			 
			 return false;
			 
		 });
		  
						
			$('body').on('click', '.event_report_result', function(event) {

            		event.preventDefault();
					
			var event_id = $(this).data('id');
			var club_id = $('#eventreportsearch input[name=club_id]').val();
			
            	 var dataString = {
            	 'event_id':event_id,
				 'club_id':club_id
            	};
				
            	dataString[site.csrf.keys.name] = site.csrf.name;
            	dataString[site.csrf.keys.value] = site.csrf.value;

            	var url = "/getSelectedEventReports";
            		{
                        // AJAX Code To Submit Form.
                        $.ajax({
                            type: "GET",
                            url:url,
                            data: dataString,
							dataType: "json",
                            cache: false,
                            success: function(result) {

            				//	console.log(result)
							
							$('#modal .modal-body .list').empty();
								
							$.each(result.reports, function(k, v){
									 
							 $(".report_template").clone().removeClass("report_template template").addClass("modalreport_").attr('id', 'modalreport_' + v.id).appendTo('#modal .modal-body .list');

			$('#modalreport_' + v.id + ' .name').html(v.name);
            $('#modalreport_' + v.id + ' .title').html(v.title);
			$('#modalreport_' + v.id + ' .copy').addClass('copy_hidden').html(v.report);
									
							});
								 
							$('#modal').modal();		 
            					},
                            error: function(jqXHR, textStatus, errorThrown) {
                         console.log( jqXHR);
                            }
                        });
                    }
                    return false;

            			});	
		
		
			
		$('body').on('mouseover', 'nav .top_link', function(event) {

            		event.preventDefault();
				
				var width = $( window ).width();
					
				if( width >= 768 ){	
					$('nav .menu_open').hide();
						$(this).closest('.dropdown').find('.dropdown-menu').addClass('menu_open').show();
						}
					});	
							
		$('body').on('mouseleave', 'nav .menu_open', function(event) {

            		event.preventDefault();
				
					var width = $( window ).width();
					
				if( width >= 768 ){	$(this).removeClass('menu_open').hide(); }
						return false;
					});
		
		
	/* album control */
				
		$('body').on('click', '.carousel_wrapper .direction', function(event) {
			
			event.preventDefault();

			var container = $(this).closest('.carousel_wrapper').find('.carousel').attr('id');
			
			if( $(this).hasClass('left') ){
			
					$('#'+container).animate({
							scrollLeft: "-=300px"
						  }, "slow");
				  
			}
			
			if( $(this).hasClass('right') ){
			
					$('#'+container).animate({
							scrollLeft: "+=300px"
						  }, "slow");
				  
			}
			
			
			
					});
	
	
		
	/* banner img link */
				
		$('body').on('click', '.img_link', function(event) {
			
			event.preventDefault();

			var url = $(this).data('link');
			
			window.open(url, '_blank').focus();
			
			
					});
		
		  $('body').on('click', '.view_route', function(event) {
	 
	 event.preventDefault();
	  
	  let id = $(this).data('route')
	  
		loadGPXFileIntoGoogleMap(id);
				
	  
  })

	
function loadGPXFileIntoGoogleMap(id) {
	
	var mapCont = 'route_map';
	
	let color1="rgb(255,120,0)"
	
	 var dataString = {
        "id": id
    };
				var ajaxRequest = $.ajax({
                           		type: "GET",
                                url: "/viewraceroute",
								data: dataString,
                                cache: false,
                                dataType: "json",
                                });

                                ajaxRequest.done(function(result) {
								
									var data = result.data;
									var trainingCoords = [];
									
									var mapStart;
								 $.each(data, function(k, v) {
									 
									 var latCoord = parseFloat(v.lat);
									 var lonCoord = parseFloat(v.lon);
									 
							  var coords = {lat: latCoord,
											lng: lonCoord };
											
									if( k == 0 ){
										 
								mapStart = {lat: latCoord,
											lng: lonCoord };
										 
									 }
											  
								trainingCoords.push(coords); 
								 })
								 
								 
								// console.log(trainingCoords);
							const map = new google.maps.Map(document.getElementById(mapCont), {
									zoom: 3,
									mapTypeId: "terrain",
								  });
								  	 
							var bounds = new google.maps.LatLngBounds();
								for (var i = 0; i < trainingCoords.length; i++) {
								  bounds.extend(trainingCoords[i]);
							}
								 
								  const trainingPath = new google.maps.Polyline({
									path: trainingCoords,
									geodesic: true,
									strokeColor: color1,
									strokeOpacity: 1.0,
									strokeWeight: 2,
								  });
								  
								new google.maps.Marker({
									position: mapStart,
									map,
								  });  

					map.fitBounds(bounds);

				trainingPath.setMap(map);

							$('#routeModal').modal('show')		
									});

                                ajaxRequest.fail(function(jqXHR, textStatus, errorThrown) {
           
		   
				
		console.log(jqXHR);
           
			 // Display errors on failure
    var debugAjax = (typeof site !== "undefined") && site.debug.ajax;

    if (debugAjax && jqXHR.responseText) {
        document.write(jqXHR.responseText);
        document.close();
    } else {
        // Destroy any previous instance
        $("#alerts-page").ufAlerts('destroy');
        // Create new instance of ufAlerts
        $("#alerts-page").ufAlerts().ufAlerts('fetch').ufAlerts('render');
    }		
		
                });
                        
                     
                        return false;
	
}


	}
		
})(jQuery, document, window);


